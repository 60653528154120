import React from 'react';
import Layout from '../../components/Layout';
import SectionIntro from '../../components/SectionIntro';
import ContactForm from '../../components/ContactForm';
import Seo from '../../components/SEO';
import {graphql, useStaticQuery} from 'gatsby';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = data;
  const bgImage = convertToBgImage(getImage(backgroundImage));
  return (
    <Layout
      seo={
        <Seo
          title="Contact Us | Trustle"
          description="We’re here to help you out. If you have any questions, just reach out to us and we’ll respond as soon as we can. Please provide as much information as possible."
        />
      }
      className="bg-cover md:pb-16"
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext className="bg-cover">
        <section className="py-16 md:py-30">
          <div className="max-w-5xl pt-0 mx-5 text-center text-white lg:mx-auto md:mx-8">
            <SectionIntro
              isPageHeader
              hero="Contact us"
              title="We’re here to help you out"
              description="If you have any questions, just reach out to us and we’ll respond as soon as we can. Please provide as much information as possible."
            />
          </div>

          <ContactForm />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default ContactPage;
